import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Button from "../../components/button/Button";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import Popup from "../../components/popup/Popup";
import { CoronaContext } from "../../context/coronaContext";
import { settings } from "../popupSettings";
import jeugdcorona from "./images/jeugd-corona.svg";
import samentoekomst from "./images/samentoekomst.png";
import reshma from "./images/reshma.jpg";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Nji from "./images/Nji.png";

gsap.registerPlugin(ScrollTrigger);

export default function Jeugd() {
  const [showJeugd, setShowJeugd] = useState(false);
  const { corona } = useContext(CoronaContext);

  const numberRef = useRef(null);
  useEffect(() => {
    gsap.from(numberRef.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      scrollTrigger: numberRef.current,
    });
  }, [numberRef]);

  return (
    <div className="section jeugd">
      {corona ? (
        <img
          className="corona-addon"
          src={jeugdcorona}
          alt="extra corona illustratie"
        />
      ) : null}
      <div className="section__title">
        <h2>Jeugdgezondheidszorg</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">250</span>vragen per maand buiten
          kantoortijden
        </p>
        <p>
          <span className="font--md">1000</span>dagen landelijke programma
          Kansrijke Start
        </p>
        <p>
          <span ref={numberRef} className="font--xl">
            2
          </span>
          podcasts Samen Toekomst Maken
        </p>
      </div>
      <Button onClick={() => setShowJeugd(!showJeugd)} />

      {corona ? (
        <CoronaNumber number="17" text="corona- persconferenties" />
      ) : null}

      {showJeugd ? (
        <Popup toggle={() => setShowJeugd(!showJeugd)}>
          <Slider {...settings}>
            <div>
              <h3>Reshma Roopram over jeugdpreventie:</h3>
              <h4>‘Samen de schouders eronder!’</h4>
              <img src={reshma} alt="Reshma Roopram" />
              <p>
                De Barendrechtse wethouder Reshma Roopram, die ook voorzitter is
                van onze Raad voor het Publiek Belang, schreef op de website van
                het Nederlands Centrum Jeugdgezondheid (NJI) een blog over de
                kracht van jeugdpreventie. ‘Met veel kinderen in Nederland gaat
                het gelukkig goed’, schrijft zij. ‘Zij blinken uit, zijn gezond
                en voelen zich gelukkig. Daarnaast is er ook een groep kinderen
                die extra aandacht nodig heeft. Denk hierbij aan kinderen die
                niet mee kunnen komen of in een ongezonde of onveilige situatie
                verkeren. In het kinderrechtenverdrag van de Verenigde Naties is
                afgesproken dat alle kinderen het recht hebben om op te groeien
                in een kansrijke, veilige en gezonde omgeving.’
              </p>{" "}
              <p>
                Lees het volledige blog:{" "}
                <a href="https://www.ncj.nl/actueel/blogs/blogpost/blog-samen-de-schouders-eronder-van-wethouder-reshma-roopram">
                  Samen de schouders eronder!
                </a>
              </p>
            </div>
            <div>
              <h3>
                Staatssecretaris Paul Blokhuis neemt aansturing Kansrijke Start
                over
              </h3>
              <p>
                Paul Blokhuis, staatssecretaris van VWS, nam in oktober de
                jeugdportefeuille over van minister Hugo de Jonge. Hij werd
                hiermee verantwoordelijk voor de jeugdgezondheidszorg, de
                jeugd-GGZ en de Jeugdwet, en het VWS-deel van de portefeuille
                huiselijk geweld en kindermishandeling. Ook het landelijke
                programma Kansrijke Start kwam daarmee onder zijn
                verantwoordelijkheid te vallen.
              </p>
              <p>
                Meer lezen over onze regionale aanpak Kansrijke Start? Klik op
                het roze kruisje bij hoofdonderwerp Kinderen in deze
                jaarverslagillustratie.
              </p>
            </div>
            <div>
              <h3>
                <span style={{ fontStyle: "italic" }}>
                  Samen Toekomst Maken
                </span>{" "}
                terugluisteren in podcasts
              </h3>
              <p>
                Het online evenement{" "}
                <span style={{ fontStyle: "italic" }}>
                  Samen Toekomst Maken
                </span>
                , op 27 oktober, was een groot succes. Honderden
                belangstellenden reisden niet af naar de Fabrique in Utrecht,
                maar namen deel vanuit hun eigen ‘luie’ stoel thuis. De
                innovatie en inspiratie deed daar niet voor onder. Er zijn
                inmiddels 2 podcasts verschenen waarin jongeren in gesprek gaan
                met sprekers.
              </p>
              <p>
                <a href="https://soundcloud.com/user-852815486/samen-toekomst-maken-de-balans">
                  Luister mee in de podcast
                </a>{" "}
                en{" "}
                <a href="https://samentoekomstmaken.nl/wp-content/uploads/2020/12/Praatplaat-Samen-Toekomst-Maken_-sessie-Ilja-scaled.jpg">
                  lees erover in de praatplaat
                </a>
                .
              </p>
              <img src={samentoekomst} alt="Samen Toekomst Maken" />
            </div>
            <div>
              <h3>Gelijke kansen voor een diverse jeugd</h3>
              <p>
                In 2020 werkten wij mee aan 4 landelijke onderzoeken. Een
                daarvan is project HEY (Health Equity in Youth) van het
                programma{" "}
                <span style={{ fontStyle: "italic" }}>
                  Gelijke kansen voor een diverse jeugd
                </span>{" "}
                van de Nationale Wetenschapsagenda. Hierin werken wij samen met
                TNO, NCJ, GGD Fryslân, LUMC, Hanze Hogeschool en Universiteit
                Groningen. Het doel:{" "}
                <a href="https://gelijkekansenvooreendiversejeugd.nl/programmas-ouders/gelijke-kansen-in-jeugdgezondheid/">
                  gelijke kansen in de jeugdgezondheid
                </a>
                .
              </p>
              <p>
                In project HEY wordt onderzocht hoe ongezond gedrag en meemaken
                van stressvolle gebeurtenissen bij jongeren samenhangt met
                psychische gezondheid. En hoe kijken jongeren zelf naar
                kwetsbaarheid en stress? Daarnaast wordt gekeken hoe uitkomsten
                van het onderzoek toepasbaar gemaakt kunnen worden voor de
                JGZ-praktijk. Meer weten? Kijk dan deze video:
              </p>
              <iframe
                src="https://player.vimeo.com/video/518261137"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Vimeo video player"
              ></iframe>
            </div>
            {corona ? (
              <div>
                <h3>Actuele corona-informatie op themapagina NJi.nl</h3>
                <p>
                  Hoe gaan we als samenleving de komende tijd om met het
                  coronavirus? Het Nederlands Jeugdinstituut (NJi) maakte{" "}
                  <a href="https://www.nji.nl/coronavirus">een themapagina</a>{" "}
                  met een verzameling van alle actuele informatie hierover.
                  Kinderen, jongeren, ouders, professionals en beleidsmakers
                  vinden hier betrouwbare informatie en antwoord op actuele
                  vragen over het coronavirus en de invloed hiervan op opgroeien
                  en opvoeden.
                </p>
                <img src={Nji} alt="nji screendump" />
              </div>
            ) : null}
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
