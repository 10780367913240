import React, { useContext, useState } from "react";
import Slider from "react-slick";
import Button from "../../components/button/Button";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import Popup from "../../components/popup/Popup";
import { CoronaContext } from "../../context/coronaContext";
import { settings } from "../popupSettings";
import scholencorona from "./images/scholen-corona.svg";
import schoolverzuim from "./images/schoolverzuim.jpg";
import pats from "./images/pats.jpg";
import meryem from "./images/meryem.jpg";
import valerie from "./images/valerie.png";

export default function Scholen() {
  const { corona } = useContext(CoronaContext);
  const [showScholen, setShowScholen] = useState(false);

  return (
    <div className="section scholen">
      {corona ? (
        <img
          className="corona-addon"
          src={scholencorona}
          alt="extra corona illustratie"
        />
      ) : null}
      <div className="section__title">
        <h2>Scholen</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">30</span>deelnemers <br />
          PATS
        </p>
        <p>
          <span className="font--md">3</span>bijeenkomsten <br />
          Met andere ogen
        </p>
        <p>
          <span className="font--xl">1</span>dag Baas <br />
          van Morgen
        </p>
      </div>
      <Button onClick={() => setShowScholen(!showScholen)} />

      {corona ? (
        <CoronaNumber
          number="5"
          text="minuten radio-interview kwetsbare kinderen"
        />
      ) : null}

      {showScholen ? (
        <Popup toggle={() => setShowScholen(!showScholen)}>
          <Slider {...settings}>
            <div>
              <h3>Mbo’er Eslem is de Baas van Morgen</h3>
              <p>
                Bestuurder Hans Butselaar stond vrijdag 24 januari voor een dag
                zijn stoel af aan de 14-jarige Eslem Altay. Dit deed hij voor
                het project{" "}
                <a href="https://www.jinc.nl/ons-werk/projecten/baasvanmorgen/">
                  Baas van Morgen
                </a>
                , een initiatief van JINC. Jeugdverpleegkundige Minke Vellinga
                maakte er een vlog over:
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/gu91hrOAO0s"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <h3>Blogserie over schoolziekteverzuim</h3>
              <p>
                Wij bieden ondersteuning aan kinderen tot 23 jaar die door
                ziekte of omstandigheden niet naar school kunnen. In een
                speciaal consult{" "}
                <span style={{ fontStyle: "italic" }}>schoolziekteverzuim</span>{" "}
                bespreekt de jeugdverpleegkundige wat de problemen zijn en welke
                ondersteuning wellicht kan helpen. We maakten er een korte
                blogserie over:
                <ul>
                  <li>
                    <a href="https://cjgrijnmond.nl/blog-over-schoolziekteverzuim-sarah-is-snel-vermoeid/">
                      Sara is snel vermoeid
                    </a>
                  </li>
                  <li>
                    <a href="https://cjgrijnmond.nl/blog-over-schoolziekteverzuim-thom-wil-geen-hulp-meer/">
                      Thom wil geen hulp meer
                    </a>
                  </li>
                  <li>
                    <a href="https://cjgrijnmond.nl/blog-over-schoolziekteverzuim-aanpak-wordt-verstoord-door-coronacrisis/">
                      Anish’ aanpak wordt verstoord door coronacrisis
                    </a>
                  </li>
                </ul>
              </p>
              <img src={schoolverzuim} alt="neerslachtige jongen" />
            </div>
            <div>
              <h3>Het belang van PATS, juist in coronatijd</h3>
              <p>
                Wanneer een leerling zich langdurig of frequent ziekmeldt, is
                het belangrijkrijk hier tijdig op in te springen. Dit om verder
                verzuim of zelfs schooluitval te voorkomen. Vaak is er namelijk
                bij schoolziekteverzuim sprake van achterliggende problemen.
              </p>
              <p>
                Tijdens de lockdown was het lastig om schoolziekteverzuim te
                signaleren en te registeren. Dat maakte het toen de scholen weer
                (deels) open gingen extra belangrijk om met elkaar oog te hebben
                voor de leerlingen en grip te houden op het ziekteverzuim. Juist
                in coronatijd. De methode PATS, onze Preventieve Aanpak
                Terugdringen Schoolziekteverzuim, kan hierbij helpen. We
                implementeerden de methodiek al op 30 scholen voor voortgezet
                onderwijs in Rotterdam, Vlaardingen en Schiedam.{" "}
                <a href="https://cjgrijnmond.nl/pats/">
                  {" "}
                  Lees meer over PATS op onze website
                </a>
                .
              </p>
              <img src={pats} alt="PATS" />
            </div>
            <div>
              <h3>
                <span style={{ fontStyle: "italic" }}>Met andere ogen:</span>{" "}
                onderwijs, zorg, jeugd
              </h3>
              <p>
                De coalitie Onderwijs – Zorg – Jeugd initieerde het programma{" "}
                <span style={{ fontStyle: "italic" }}>Met andere ogen</span>.
                Dit programma startte in 2019 en heeft als doel de samenwerking
                tussen onderwijs, zorg en jeugd te versterken, zodat kinderen
                zich optimaal kunnen ontwikkelen. De kern van de aanpak is samen
                al doende leren en ontwikkelen wat werkt.
              </p>
              <p>
                In zogenaamde ‘communities of practice’, waarin verschillende
                partijen zijn vertegenwoordigd die in het land betrokken zijn
                bij jeugd, worden diverse thema’s uitgediept om te leren van
                elkaars ervaringen en vragen. Wij hebben een aandeel in dit
                landelijke programma en nemen deel aan de community{" "}
                <span style={{ fontStyle: "italic" }}>
                  Preventief en Positief Jeugdbeleid
                </span>
                . In 2020 vonden 3 bijeenkomsten plaats waarin onder andere de
                thema’s samenwerking rond het jonge en oudere kind centraal
                stonden. Het programma loopt door in 2021.
              </p>
            </div>
            <div>
              <h3>
                Peuterconsulent Meryem Taner vertelt Onderwijs010 over haar werk
              </h3>
              <p>
                Meryem Taner werkt als peuterconsulent bij CJG Feijenoord.
                Ouders komen hier regelmatig met hun peuter op consult. In een
                interview met Onderwijs010 vertelt zij met enthousiasme over wat
                haar drijft in haar werk. Meryem: ‘Ik vind heel sterk dat ieder
                kind een gelijke kans verdient en heb zelf ervaren hoe je als
                kind achterstand op kan lopen door de omgeving waarin je
                opgroeit. Daarom wil ik ouders meegeven dat ze een grote rol
                hebben in de taalontwikkeling van hun kind.’
              </p>
              <p>
                Het hele interview staat op de{" "}
                <a href="https://onderwijs010.nl/interview-peuterconsulent-meryem/">
                  website van Onderwijs010
                </a>
                .
              </p>
              <img src={meryem} alt="Meryem Taner" />
            </div>
            {corona ? (
              <div>
                <h3>Extra aandacht voor kwetsbare kinderen, juist nu</h3>
                <p>
                  Bij de keuzes die wij moesten maken voor onze dienstverlening,
                  legden wij de hoogste prioriteit bij onze zorg aan jonge
                  kinderen, zwangeren en kwetsbare kinderen. Onze professionals
                  die verbonden zijn aan de scholen, hielden in samenwerking met
                  de scholen kwetsbare kinderen in beeld. In specifieke gevallen
                  nodigden we de kinderen uit voor een (digitaal) consult en
                  indien nodig verwezen we door naar passende hulp. Daarnaast
                  haalden onze professionals signalen op uit het netwerk en
                  namen zij proactief contact op met de gezinnen die (mogelijk)
                  kwetsbaar zijn.
                </p>
                <p>
                  Jeugdverpleegkundige Minke Vellinga is een van onze
                  professionals die zich onvermoeibaar blijft inzetten voor
                  kwetsbare kinderen in de schoolleeftijd en daar ook in de
                  media aandacht voor vraagt. Zo werkte ze mee aan een{" "}
                  <a href="https://www.nporadio1.nl/binnenland/22953-5200-leerlingen-buiten-beeld-geen-onderwijskwestie-maar-een-veiligheidskwestie">
                    radio-interview bij Spraakmakers.
                  </a>{" "}
                  Bij het item van Spraakmakers is Minke aan het woord vanaf
                  minuut 5.31.
                </p>
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>CJG-professionals ondersteunen GGD Rotterdam-Rijnmond</h3>
                <img src={valerie} alt="quote van Valerie" />
                <p>
                  Vanuit een bestaand convenant en het crisisplan Infectiezieken
                  ondersteunden een aantal van onze professionals (11 fte) van
                  maart tot september GGD Rotterdam-Rijnmond. Jeugdarts Valerie
                  Schokkenbroek was een van hen. Ze deelde geregeld haar
                  ervaringen: 'We nemen monsters af bij patiënten thuis en
                  telefonisch beantwoorden wij vragen van huisartsen en mensen
                  uit de regio. Ik vind het mooi om te zien hoe de samenwerking
                  verloopt: naast het CJG helpen ook andere organisaties de GGD,
                  en CJG-collega’s nemen mijn gewone taken over in de weken dat
                  ik bij de GGD werk! Wat mij erg opvalt is dat er veel
                  emotionele en sociale steun is onderling. Laten we dat vooral
                  vasthouden als alles weer normaler wordt!
                </p>
              </div>
            ) : null}
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
