import React from "react";
import corona from "../svg/corona-geel.svg";

export default function CoronaNumber({ number, text }) {
  return (
    <div className="coronaNumber">
      <div className="coronaNumber__number">
        <img src={corona} alt="corona" />
        <p className="font--md">{number}</p>
      </div>
      <p>{text}</p>
    </div>
  );
}
