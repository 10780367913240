import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Button from "../../components/button/Button";
import Popup from "../../components/popup/Popup";
import { settings } from "../popupSettings";
import verzorgingsgebied from "./images/verzorgingsgebied.jpg";
import signalen from "./images/signalen.jpg";
import toyswrap from "./images/toyswrap.jpg";
import museumnacht from "./images/museumnacht.jpg";
import { CoronaContext } from "../../context/coronaContext";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import gemeentencorona from "./images/gemeenten-corona.svg";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import nieuwsbrief from "./images/nieuwsbrief.png";

gsap.registerPlugin(ScrollTrigger);

export default function Gemeenten() {
  const { corona } = useContext(CoronaContext);
  const [showGemeenten, setShowGemeenten] = useState(false);

  const numberRef = useRef(null);
  useEffect(() => {
    gsap.from(numberRef.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      scrollTrigger: numberRef.current,
    });
  }, [numberRef]);

  return (
    <div className="section gemeenten">
      {corona ? (
        <img
          className="corona-addon"
          src={gemeentencorona}
          alt="extra corona illustratie"
        />
      ) : null}
      <div className="section__title">
        <h2>Gemeenten</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">1</span>Museumnacht
        </p>
        <p>
          <span className="font--md">56</span>deelnemers OuderTeam
        </p>
        <p>
          <span ref={numberRef} className="font--xl">
            14
          </span>
          gemeenten
        </p>
        <p>
          <span className="font--md">5000</span>Sinterklaas- cadeaus Toys ‘N
          Wrap
        </p>
      </div>

      <Button onClick={() => setShowGemeenten(!showGemeenten)} />

      {corona ? (
        <CoronaNumber number="18" text="maart CJG-nieuwsbrief corona" />
      ) : null}

      {showGemeenten ? (
        <Popup toggle={() => setShowGemeenten(!showGemeenten)}>
          <Slider {...settings}>
            <div>
              <h3>Verzorgingsgebied: 14 gemeenten, 275.364 kinderen</h3>
              <p>
                Gezond en veilig opgroeien, dat wensen wij voor alle kinderen!
                Daarom bieden wij preventieve jeugdgezondheidzorg en voeren wij
                het Rijksvaccinatieprogramma uit. Dat doen we in 14 gemeenten:
                Albrandswaard, Barendrecht, Brielle, Goeree-Overflakkee,
                Hellevoetsluis, Krimpen aan den IJssel, Lansingerland,
                Maassluis, Nissewaard, Ridderkerk, Rotterdam, Schiedam,
                Vlaardingen en Westvoorne.
              </p>
              <img
                src={verzorgingsgebied}
                alt="kaart van het verzorgingsgebied"
              />
              <p>
                Verspreid over 51 CJG-locaties zien wij de kinderen in ons
                werkgebied regelmatig, tijdens reguliere en extra
                contactmomenten vanaf de geboorte tot aan volwassenheid. Om
                hoeveel kinderen dat gaat? In 2020 woonden er 275.364 0- tot
                18-jarigen in ons werkgebied, van wie 260.948 bij ons in zorg
                waren.
              </p>
            </div>
            <div>
              <h3>Top 6 signalen gedeeld met gemeenten</h3>
              <p>
                Wij adviseren de gemeenten in ons werkgebied op basis van
                inhoudelijke, kwantitatieve en kwalitatieve rapporten, waaronder
                ook trendanalyses. Met de methodiek{" "}
                <span style={{ fontStyle: "italic" }}>Signalen ophalen</span>{" "}
                verzamelen onze professionals signalen op alle locaties. In
                september ontving iedere gemeente een nieuwe factsheet met de
                resultaten. Zowel de belangrijkste gemeentespecifieke thema’s
                als de top 6 signalen die CJG-breed spelen werden hierop
                toegelicht, zoals (complexe) scheidingen en overgewicht. Het kan
                onder meer dienen als input voor het lokaal preventief
                jeugdbeleid.
              </p>
              <img src={signalen} alt="top 6 signalen rotterdam-rijnmond" />
            </div>
            <div>
              <h3>Samenwerking CJG en Toys ’n Wrap</h3>
              <h4>Meer dan 5000 Sinterklaascadeautjes uitgedeeld</h4>
              <p>
                Voor sommige kinderen is het niet vanzelfsprekend om cadeautjes
                te krijgen tijdens Sinterklaas. Daarom werden voor
                Sinterklaasavond 2020 meer dan 5000 cadeautjes uitgedeeld aan
                gezinnen met een krappe portemonnee. Dit deden wij samen met
                Toys ‘n Wrap en meer dan 30 netwerkpartners.
              </p>
              <p>
                <a href="https://cjgrijnmond.nl/meer-dan-5000-sinterklaascadeautjes-voor-kinderen-in-rotterdam/">
                  Lees het hele bericht
                </a>
                .
              </p>
              <img src={toyswrap} alt="jongen met zwartepietenmuts" />
            </div>
            <div>
              <h3>PBS in de wijk: oefenen met positief gedrag loont</h3>
              <p>
                In meerdere Rotterdamse wijken is de aanpak Positive Behaviour
                Support{" "}
                <span style={{ fontStyle: "italic" }}>(PBS in de wijk)</span>{" "}
                omarmd. Onze (ortho)pedagogen spelen hierin een actieve rol, als
                coach of deelnemer aan kernteams.
                <span style={{ fontStyle: "italic" }}>PBS in de wijk</span> is
                een wijkbrede gedragsaanpak, die professionals, vrijwilligers,
                ouders en andere geïnteresseerden in een wijk concrete
                handvatten geeft, om samen te bouwen aan een duurzaam, positief
                wijkklimaat. Doel van de aanpak is om sociaal gedrag van
                kinderen en jongeren te bevorderen en gedragsproblemen zoveel
                mogelijk te voorkomen. De gedachte erachter is: oefenen met
                positief gedrag loont.{" "}
                <a href="https://www.leraar24.nl/423401/pbs-in-de-wijk-samenwerken-aan-een-veilig-wijkklimaat-op-school-en-in-de-buurt/">
                  Leraar24 maakte een filmpje over PBS in de wijk Crooswijk
                </a>
                .
              </p>

              <iframe
                width="440"
                height="270"
                src="https://www.leraar24.nl/api/video/218121723/embedded/swpbs-in-het-voortgezet-onderwijs/"
                frameborder="0"
                allowscreen
                title="vimeo video player"
              ></iframe>
            </div>
            <div>
              <h3>Museumnacht 010? Wij waren erbij!</h3>
              <p>
                Vaccineren: prima of liever niet? Wie tijdens de Museumnacht,
                van zaterdag 7 op zondag 8 maart, in het Natuurhistorisch Museum
                naar de tentoonstelling{" "}
                <span style={{ fontStyle: "italic" }}>Vaccineren Ja! Nee?</span>{" "}
                ging, kon zich laten injecteren met wetenschappelijk onderbouwde
                informatie, met professionals van het CJG en de GGD van
                gedachten wisselen of een meningococktail drinken.
              </p>
              <p>
                Over vaccineren lopen de meningen sterk uiteen. De
                vaccinatietentoonstelling in het Natuurhistorisch Museum ging in
                op veelvoorkomende onderwerpen in het vaccinatiedebat. Iedereen
                kreeg wetenschappelijk onderbouwde, feitelijke informatie om
                daarmee een goed geïnformeerde keuze te maken. Meer lezen? Dat
                kan op onze{" "}
                <a href="https://centrumvoorjeugdengezin.nl/actueel/museumnacht-010-wij-zijn-erbij">
                  website voor ouders en verzorgers
                </a>
                .
              </p>
              <img src={museumnacht} alt="museumnacht afbeelding" />
            </div>
            {corona ? (
              <div>
                <h3>
                  Extra nieuwsbrief voor stakeholders en samenwerkingspartners
                </h3>
                <p style={{ fontStyle: "italic" }}>
                  De verspreiding van het coronavirus (COVID-19) en de
                  onvoorspelbaarheid daarvan, beheersen op dit moment onze
                  samenleving. De verscherpte landelijke maatregelen, zoals
                  afgelopen zondag zijn ingesteld door de regering, hebben
                  vanzelfsprekend ook invloed op de dienstverlening van CJG
                  Rijnmond. Zo verminderen wij het aantal contactmomenten, doen
                  wij consulten waar mogelijk telefonisch en gaan de
                  vaccinatiedagen niet door. Wij hebben daarvoor nauw contact
                  met het RIVM, GGD Rotterdam-Rijnmond en volgen de landelijke
                  richtlijnen van de JGZ.
                </p>
                <p>
                  Met deze woorden begon de extra nieuwsbrief die wij op
                  woensdag 18 maart verstuurden. De{" "}
                  <a href="https://cjgrijnmond.nl/nieuwsbrief-23/">
                    speciale uitgave
                  </a>{" "}
                  van onze{" "}
                  <a href="https://cjgrijnmond.nl/nieuwsbrief/">
                    maandelijkse digitale nieuwsbrief
                  </a>{" "}
                  voor samenwerkingspartners, stakeholders en professionals
                  stond volledig in het teken van het coronavirus en onze
                  aangepaste dienstverlening.
                </p>
                <img src={nieuwsbrief} alt="nieuwsbrief" />
              </div>
            ) : null}
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
