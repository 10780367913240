import React, { useContext, useState, useRef, useEffect } from "react";
import Button from "../../components/button/Button";
import ouderscorona from "./images/ouders-corona.svg";
import Popup from "../../components/popup/Popup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../popupSettings";
import { CoronaContext } from "../../context/coronaContext";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import kindDuimOmhoog from "./images/kindmondkapje.jpg";
import zwangeren from "./images/zwangeren.png";
import kindertelefoon from "./images/kindtelefoonbellenmeisje.jpg";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import weekplanner from "./images/Weekplanner_CJG.jpg";
import oudersnieuwsbrief from "./images/oudersnieuwsbrief.png";
import richtlijnen from "./images/richtlijnen.png";
import posterhandenwassen from "./images/poster-handen-wassen.jpg";

gsap.registerPlugin(ScrollTrigger);

export default function Ouders() {
  const [showOuders, setShowOuders] = useState(false);
  const { corona } = useContext(CoronaContext);

  const numberRef = useRef(null);

  console.log("what is corona switch ", corona);

  useEffect(() => {
    gsap.from(numberRef.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      scrollTrigger: numberRef.current,
    });
  }, [numberRef]);

  return (
    <div className="section ouders">
      {corona ? (
        <img
          className="corona-addon"
          src={ouderscorona}
          alt="extra corona illustratie"
        />
      ) : null}

      <div className="section__title">
        <h2>Ouders</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">130.755</span>
          telefoontjes naar <br /> Service & Contact
        </p>
        <p>
          <span ref={numberRef} className="font--xl">
            7,8
          </span>
          rapportcijfer <br /> klanttevredenheid
        </p>
      </div>

      {corona ? (
        <CoronaNumber number="1" text="begeleider mee naar afspraak op CJG" />
      ) : null}

      <Button onClick={() => setShowOuders(!showOuders)} />

      {showOuders ? (
        <Popup toggle={() => setShowOuders(!showOuders)}>
          <Slider {...settings}>
            <div>
              <h3>Bijna 90.000 extra contactmomenten in 2020</h3>
              <p>
                Een extra contactmoment is een individueel contactmoment waarin
                wij zorgen of vragen oppakken door een screening of analyse. Op
                ieder moment kan het noodzakelijk zijn om kinderen een extra
                contactmoment aan te bieden. Dit kan variëren van een
                lichamelijke screening tot een consult over psychosociale
                problematiek. In het laatste geval bieden onze professionals
                jeugdigen en ouders een langer extra contactmoment aan waarin
                zij onder andere een analyse maken van de ervaren problematiek.
                Daarnaast wordt de balans opgemaakt tussen draagkracht en
                draaglast.
              </p>
              <p>
                Tijdens de lockdownperiodes was er een grote toename van
                telefonisch contact met onze klanten. In totaal hadden wij in
                2020 bijna 90.000 extra contactmomenten. Dat is een realisatie
                van 139%.
              </p>
            </div>
            <div>
              <h3>Klanten geven CJG Rijnmond rapportcijfer 7,8</h3>
              <p>
                Wij onderzoeken doorlopend hoe tevreden onze klanten zijn met
                onze dienstverlening. Daarbij kijken we naar de aspecten
                bereikbaarheid, bejegening, deskundigheid, verwachting en
                contactfrequentie. Dat we in 2020 een groot deel van onze
                dienstverlening telefonisch en via beeldbellen deden, maakte
                voor de tevredenheid niet uit: het gemiddelde rapportcijfer voor
                alle gemeenten waarin wij JGZ bieden steeg zelfs naar een 7,8
                (in 2019 was dit een 7,6).
              </p>
              <p>
                Vooral de onderdelen bejegening en deskundigheid scoren
                doorlopend hoog: gemiddeld ver boven de 8. De waardering over de
                bereikbaarheid was in 2020 ook hoger dan een 8. De tevredenheid
                over de wachttijd steeg naar 90%; het lijkt erop dat onze
                strakke planning door de maatregelen tegen het coronavirus
                zorgen voor een korte wachttijd op onze CJG-locaties.
              </p>
              <img
                src={kindDuimOmhoog}
                alt="kind met mondkapje steekt duim omhoog"
              />
            </div>
            <div>
              <h3>6.196 zwangeren halen kinkhoestvaccinatie</h3>
              <p>
                Sinds december 2019 kunnen vrouwen vanaf 22 weken zwangerschap
                bij ons terecht voor een gratis kinkhoestvaccinatie. Ze
                beschermen daarmee zichzelf en hun baby: de zwangere krijgt zelf
                geen kinkhoest én draagt via de placenta antistoffen over naar
                haar baby. Zo is de baby de eerste maanden na de geboorte
                beschermd tegen deze ziekte.
              </p>
              <p>
                Hoewel we onze reguliere inloopspreekuren voor kinderen van 0
                tot 4 jaar door de maatregelen tegen het coronavirus
                annuleerden, lukte het wel om de inloopspreekuren voor de
                kinkhoestvaccinatie door te laten gaan. In 2020 wisten 6.196
                zwangeren ons hiervoor te vinden.
              </p>

              <p>
                Aan de bezoekersaantallen op onze website
                eenprikzogepiept.nl/zwangeren merkten we dat er geregeld
                informatie over deze vaccinatie en de inloopspreekuren werd
                opgezocht: de{" "}
                <a href="https://www.eenprikzogepiept.nl/kinkhoest-zwangeren">
                  pagina voor zwangeren
                </a>{" "}
                werd ruim 30.000 bezocht. De{" "}
                <a href="https://www.eenprikzogepiept.nl/verloskundig-professional">
                  {" "}
                  pagina met informatie voor professionals
                </a>{" "}
                bijna 9.000 keer.
              </p>
              <img
                src={zwangeren}
                alt="een screenprint met twee zwangere vrouwen"
              />
            </div>
            <div>
              <h3>Pilot 24/7 bereikbaarheid jeugdgezondheidzorg</h3>
              <p>
                In april startten wij met de{" "}
                <a href="https://cjgrijnmond.nl/pilot-cjg-24-7-bereikbaar/">
                  pilot 24/7 bereikbaarheid jeugdgezondheidszorg
                </a>
                . Ouders, verzorgers en jongeren konden 6 maanden lang ook
                buiten kantoortijden bij ons terecht met al hun opvoedvragen.
              </p>
              <p>
                We kijken positief terug op de pilot. Per maand wisten ongeveer
                250 klanten ons te vinden, 75% telefonisch en 25% via WhatsApp.
                De vragen waren vergelijkbaar met de vragen die ons
                contactcentrum overdag krijgt. Ze gingen voornamelijk over heel
                jonge kinderen. Piekmomenten zagen we vooral in de vroege avond
                tussen 18.00 en 21.00 uur. In het weekend ging het om enkele
                tientallen contactmomenten.
              </p>
              <p>
                Hoewel we tevreden zijn met de resultaten, is het op dit moment
                (nog) niet mogelijk deze dienstverlening voort te zetten.
                Hiervoor zijn meer deelnemende partijen nodig. Het NCJ werkt
                hieraan en neemt daarin de resultaten van deze pilot mee.
              </p>
              <img src={kindertelefoon} alt="meisje met oranje telefoon" />
            </div>
            {corona ? (
              <div>
                <h3>Online coronavraagbaak voor ouders</h3>
                <p>
                  Is mijn contactmoment digitaal of fysiek, met welke
                  maatregelen moet ik rekening houden als ik naar een
                  CJG-locatie kom en kan de afspraak doorgaan als mijn kind
                  verkouden is? Voor antwoord op deze en andere coronavragen
                  maakten we gelijk na de uitbraak van het coronavirus een
                  speciale coronapagina{" "}
                  <a href="https://centrumvoorjeugdengezin.nl/themas/corona">
                    CJG en het coronavirus
                  </a>
                  . Naast actuele informatie, staan hier handige apps en
                  websites, blogs van professionals en bijvoorbeeld een week- en
                  dagplanning om ouders te ondersteunen bij de combinatie
                  thuiswerken, kinderen opvangen en lesgeven.
                </p>
                <div>
                  <img src={weekplanner} alt="weekplanner" />
                  {/* <img src={dagplanner} alt="dagplanner" /> */}
                </div>
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>85.000 ouders krijgen coronanieuwsbrief</h3>
                <p>
                  Om de ouders en verzorgers in ons werkgebied zo goed mogelijk
                  te informeren over de gevolgen van het coronavirus voor onze
                  dienstverlening, verstuurden we eind maart 2020 een{" "}
                  <a href="https://mailchi.mp/246c692f871f/nieuwsbrief-coronavirus-en-het-centrum-voor-jeugd-en-gezin">
                    digitale nieuwsbrief
                  </a>
                  . We vertelden daarin onder meer over onze maatregelen en de
                  vaccinatiedagen die niet doorgingen. Ook gaven we antwoord op
                  de meestgestelde vragen van ouders en verwezen we naar de
                  website{" "}
                  <a href="https://www.centrumvoorjeugdengezin.nl/corona">
                    centrumvoorjeugdengezin.nl/corona
                  </a>
                  . In april en mei verstuurden we de 85.000 ontvangers opnieuw
                  per e-mail actuele informatie.
                </p>
                <img src={oudersnieuwsbrief} alt="ouders nieuwsbrief" />
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>
                  Richtlijnen CJG-locaties: 1,5 meter afstand, 1 begeleider en
                  handen wassen
                </h3>
                <p>
                  Op onze CJG-locaties waren de veiligheidsmaatregelen en
                  RIVM-richtlijnen al snel zichtbaar en voelbaar. Het vroeg
                  steeds aanpassingsvermogen van zowel onze professionals als
                  onze klanten. We zetten in op zo min mogelijk verkeer van
                  personen. Dit om besmettingsrisico te minimaliseren, voldoende
                  ruimte te hebben om de 1,5 meter afstandsregel te kunnen
                  hanteren en contactmomenten met lichamelijk contact veilig
                  door te kunnen laten gaan. We planden onze spreekuren minder
                  vol en minder tegelijkertijd, zodat zo min mogelijk ouders en
                  kinderen op hetzelfde moment bij ons binnen waren.{" "}
                </p>
                <p>
                  Aan onze klanten vroegen we onder meer om met slechts één
                  begeleider per kind te komen, bij binnenkomst de handen te
                  wassen en een registratieformulier in te vullen. Dit vroeg nog
                  wel eens om uitleg en geduld van onze assistenten en
                  baliemedewerkers. Om hen bij deze soms moeilijke gesprekken te
                  ondersteunen, organiseerden we in het najaar bijeenkomsten via
                  Microsoft Teams om ervaringen uit te wisselen en tips te
                  delen.
                </p>
                <img src={richtlijnen} alt="coronavirus richtlijnen" />
                <img src={posterhandenwassen} alt="poster handen wassen" />
              </div>
            ) : null}
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
