import React, { useContext } from "react";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import { CoronaContext } from "../../context/coronaContext";

export default function Socials() {
  const { corona } = useContext(CoronaContext);

  return (
    <div className="section socials">
      <div className="section__numbers">
        <p>
          <span className="font--md">4.241</span>LinkedInvolgers
        </p>
        <p>
          <span className="font--md">1.388</span>Twittervolgers
        </p>
        <p>
          <span className="font--md">8.000</span>Facebookvolgers
          <br />
          92% <br />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10.56 16.94"
            style={{
              strokeMiterlimit: "10",
              strokeWidth: "1.5px",
              height: "2vw",
            }}
            className="vrouwenicon"
          >
            <g id="Laag_2" data-name="Laag-2">
              <g id="Cijfers">
                <circle class="cls-1" cx="5.28" cy="5.28" r="4.53" />
                <line
                  className="cls-1"
                  x1="5.28"
                  y1="9.81"
                  x2="5.28"
                  y2="16.94"
                />
                <line
                  className="cls-1"
                  x1="1.95"
                  y1="13.38"
                  x2="8.61"
                  y2="13.38"
                />
              </g>
            </g>
          </svg>
        </p>
      </div>

      {corona ? (
        <CoronaNumber number="85.000" text="corona nieuwsbrieven" />
      ) : null}
    </div>
  );
}
