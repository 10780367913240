import React, { useContext } from "react";
import { CoronaContext } from "../../context/coronaContext";
import Toggleswitch from "../../components/toggleswitch/Toggleswitch";
import presentator from "../../images/SVG/presentator.svg";
import presentatorMondkap from "../../images/SVG/mondkapman.svg";
import people from "../../images/SVG/top/desktop-top.svg";
import peopleMobile from "../../images/SVG/peoplemobile.svg";
import Player from "../../components/audioplayer/Player";
import { ReactComponent as Plusicon } from "../../components/svg/button.svg";

export default function Hero() {
  const { corona, setCorona } = useContext(CoronaContext);

  console.log("wat corona ", corona);

  return (
    <div className="hero">
      <img
        className="hero__presentator"
        src={corona ? presentatorMondkap : presentator}
        alt="presentator"
      />
      <img className="hero__people" src={people} alt="verschillende mensen" />

      <div className="hero__textballoon">
        <div className="text">
          <h1>
            2020 in beeld <br />
            (en in de buurt)
          </h1>
          <div className="audioplayer">
            <Player />
          </div>
          <p>
            Het jaar 2020 was voor ons niet alleen het jaar van het coronavirus.
            Het was ook het jaar waarin{" "}
            <span className="font--bold">6.196</span> zwangeren een vaccinatie
            haalden tegen kinkhoest, <span className="font--bold">35</span>{" "}
            mannen bij ons werkten (tegenover 646 vrouwen) en we{" "}
            <span className="font--bold">297.000</span> contactmomenten hadden
            met een van de kinderen uit ons werkgebied.
          </p>
          <p>
            Luister naar de woorden van onze bestuurder Hans Butselaar, bekijk
            de cijfers terwijl je door dit visuele jaarverslag wandelt en klik
            per thema op{" "}
            <span>
              <Plusicon className="plusicon" />
            </span>
            . Je krijgt dan een korte toelichting op de hoofdpunten. Lees je ook
            het{" "}
            <a
              href="https://jaarverslag2020.cjgrijnmond.nl/CJG_Rijnmond_Jaarverslag_2020.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              financiële jaarverslag 2020
            </a>
            ?
          </p>
          <div className="corona">
            <div className="corona__toggle">
              <Toggleswitch
                name="coronatoggle"
                checked={corona}
                onChange={setCorona}
              />
            </div>
            <div className="corona__text">
              <p>
                <span className="font--bold">Tip:</span> kijk eens wat er
                gebeurt als je het schuifje AAN (of juist UIT) zet.
              </p>
            </div>
          </div>
        </div>
      </div>

      <img
        className="hero__people--mobile"
        src={peopleMobile}
        alt="verschillende mensen"
      />
    </div>
  );
}
