import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Button from "../../components/button/Button";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import Popup from "../../components/popup/Popup";
import { CoronaContext } from "../../context/coronaContext";
import { settings } from "../popupSettings";
import organogram from "./images/Organogram_DEF.png";
import missie from "./images/missie.jpg";
import strategie from "./images/strategie.jpg";
import visie from "./images/visie.jpg";
import jaarballon from "./images/jaarballon.svg";
import inzetbaarheid from "./images/inzetbaarheid.jpg";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import cjgcorona from "./images/cjg-corona.svg";
import MannenIcon from "../../images/SVG/mannetje.svg";

gsap.registerPlugin(ScrollTrigger);

export default function CjgRijnmond() {
  const { corona } = useContext(CoronaContext);
  const [showCjg, setShowCjg] = useState(false);

  const numberRef = useRef(null);

  useEffect(() => {
    gsap.from(numberRef.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      delay: 1,
      scrollTrigger: numberRef.current,
    });
  }, [numberRef]);

  return (
    <div className="section cjgRijnmond">
      {corona ? (
        <img
          className="corona-addon"
          src={cjgcorona}
          alt="extra corona illustratie"
        />
      ) : null}
      <div className="section__title">
        <h2>CJG</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">51</span>
          locaties
        </p>
        <p>
          <span ref={numberRef} className="font--xl">
            681
          </span>
          professionals
          <span className="font--sm">
            35{" "}
            <span>
              <img
                src={MannenIcon}
                alt="mannelijk icoon"
                style={{ height: "2vw" }}
              />
            </span>
          </span>
        </p>
        <p>
          jubileum <br /> (géén feestje)
        </p>
        <Button onClick={() => setShowCjg(!showCjg)} />
      </div>

      {corona ? (
        <CoronaNumber number="78.407" text="minder kopjes koffie" />
      ) : null}

      {/* slider */}
      {showCjg ? (
        <Popup toggle={() => setShowCjg(!showCjg)}>
          <Slider {...settings}>
            <div>
              <h3>Organogram</h3>
              <p>
                Onze organisatie, de stichting CJG Rijnmond, werd opgericht op 1
                januari 2010. De belangen van de 14 gemeenten waarvoor wij ons
                werk doen, worden behartigd door de Raad voor het Publiek
                Belang. De Raad van Toezicht ziet toe op ons beleid en
                adviseert. De eindverantwoordelijkheid voor wat wij doen ligt
                bij onze Raad van Bestuur, deze is belast met de dagelijkse
                leiding. Meer informatie? Kijk dan op{" "}
                <a href="https://cjgrijnmond.nl/onze-missie-en-visie/cjg-rijnmond-organisatie/">
                  onze website voor professionals: cjgrijnmond.nl
                </a>
                .
              </p>
              <img src={organogram} alt="de organogram van CJG Rijnmond" />
            </div>
            <div>
              <h3>Herijking strategische koers</h3>
              <h4>Samenwerken aan de jeugdgezondheidszorg van de toekomst</h4>
              <p>
                Na het intensieve proces dat wij in 2019 al doorliepen, keurde
                in april 2020 de Raad voor het Publiek Belang onze nieuwe koers{" "}
                <span style={{ fontStyle: "italic" }}>
                  Samenwerken aan de jeugdgezondheidszorg van de toekomst
                  2020-2023
                </span>{" "}
                goed. Ons plan om de lancering hiervan in 2020 feestelijk vorm
                te geven met onze professionals, opdrachtgevers, klanten en
                samenwerkingspartners, als kroon op ons 10-jarig bestaan, liep
                door het coronavirus helaas anders dan gepland. Wel maakte onze
                bestuurder Hans Butselaar deze video.
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/TAF7jrB9gS0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div className="mvs">
                <h4>Missie: 'Op tijd voor ieder kind'</h4>

                <div className="text">
                  <img src={missie} alt="missie" />
                  <p>
                    Preventie is de basis van onze dienstverlening. Wij
                    bevorderen dat kinderen gezond en veilig kunnen opgroeien;
                    dat staat voorop bij alles wat wij doen. Daarvoor sluiten
                    wij, op tijd, aan bij de leefwereld waarin zij opgroeien en
                    ondersteunen wij ouders bij de opvoeding. Wij zijn er voor
                    ieder kind en elk gezin.
                  </p>
                </div>
              </div>
              <div className="mvs">
                <h4>Visie: ‘Deskundig en passend’</h4>

                <div className="text">
                  <img src={visie} alt="visie" />
                  <p>
                    Ouders en kinderen weten ons te vinden, en wij hen. Geen
                    kind is hetzelfde, daarom bieden wij deskundig maatwerk: ons
                    dienstenaanbod is eigentijds, flexibel en past bij wat
                    gezinnen nodig hebben én willen. Als proactieve
                    professionals werken wij vanuit onze expertise samen met
                    onze partners in het netwerk, en leveren wij een bijdrage
                    aan de maatschappelijke verantwoordelijkheid om kinderen
                    gezond en veilig te laten opgroeien.
                  </p>
                </div>
              </div>
              <div className="mvs">
                <h4>Strategische speerpunten 2020-2023</h4>
                <div className="text">
                  <img src={strategie} alt="strategie" />
                  <ul>
                    <li>
                      Wij ontwikkelen een eigentijds, gedifferentieerd en
                      flexibel aanbod, zodat ouders en kinderen bij hun behoefte
                      passende diensten kunnen afnemen.
                    </li>
                    <li>
                      Wij zijn expert op het gebied van jeugdgezondheidszorg,
                      preventie, opvoeden en opgroeien. Onze stakeholders en
                      samenwerkingspartners herkennen en erkennen dat. Wij
                      leveren een actieve bijdrage aan het tot stand komen van
                      de preventieagenda van onze gemeenten.
                    </li>
                    <li>
                      Wij verbeteren onze organisatie, processen en systemen
                      continu om onze dienstverlening aan te laten sluiten op de
                      behoeften van ouders en kinderen en te voldoen aan
                      professionele standaarden. Wij streven hierbij uniforme
                      kwaliteit na en monitoren onze resultaten.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <h3>Feestelijk jubileumjaar, geen feest</h3>
              <p>
                Onze organisatie werd opgericht op 1 januari 2010: op 1 januari
                2020 bestonden wij dus 10 jaar! Tien jaar waarin we samen
                uitdagingen aangingen, groeiden en ons volop inzetten met
                #hartvoorallekinderen. Een enthousiast organisatieteam maakte
                een mooi plan om dit feestelijk te vieren. Een plan met ruimte
                voor het verleden, heden en de toekomst. Het heden haalde de
                plannen in. Maar: ook 12,5 jaar is een jubileum. Daar kijken we
                naar uit!
              </p>
              <img src={jaarballon} alt="ballon met 10 jaar" />
            </div>
            <div>
              <h3>Datalek bij CJG Rijnmond</h3>
              <p>
                In het voorjaar van 2020 probeerden onbevoegden door phishing in
                te breken in ons computersysteem. Dit is een vorm van
                internetfraude en daarom strafbaar. Wij ondernamen direct actie.
                Zo deden wij een officiële melding bij de Autoriteit
                Persoonsgegevens, deden wij aangifte en schakelden wij een
                gespecialiseerd bureau in om uitgebreid onderzoek te doen. Uit
                dat onderzoek bleek gelukkig dat er geen toegang is geweest tot
                cliëntdossiers.
              </p>
            </div>
            <div>
              <h3>Duurzame inzetbaarheid: zo doen wij dat bij CJG Rijnmond</h3>
              <p>
                Wij vinden het belangrijk dat onze professionals gemotiveerd
                zijn, presteren en zich kunnen ontwikkelen. Ook hechten we er
                grote waarde aan dat zij gelukkig zijn in hun werk en leven, en
                gezond zijn en blijven. Daarom stond het jaar 2020 voor onze
                afdeling HRM in het teken van duurzame inzetbaarheid.
              </p>
              <p>
                In{" "}
                <a href="https://cjgrijnmond.nl/duurzame-inzetbaarheid-zo-doen-we-dat-bij-cjg-rijnmond/">
                  een interview legt HRM-adviseur Ramon van Dijk
                </a>{" "}
                de keuze voor dit thema uit: ‘In bijvoorbeeld een autofabriek
                zijn de machines die de auto’s maken het belangrijkste kapitaal.
                Wanneer in zo’n fabriek een machine kapot is, kun je die
                repareren of vervangen. Bij CJG Rijnmond zijn onze medewerkers
                het kapitaal. Onze medewerkers hebben waardevolle expertise en
                kennis in huis, we willen dus graag dat ze zich goed voelen en
                gelukkig zijn in hun werk.’
              </p>
              <p>
                Op onze website voor professionals staan{" "}
                <a href="https://cjgrijnmond.nl/?s=duurzame+inzetbaarheid&submit=">
                  {" "}
                  meer interviews over dit thema
                </a>
                . Onder meer over duurzame inzetbaarheid in coronatijd, fysiek
                en mentaal fit of onze{" "}
                <span style={{ fontStyle: "italic" }}>CJG-winterboost</span>.
              </p>
              <img
                src={inzetbaarheid}
                alt="verschillende foto's in een collage over duurzame inzetbaarheid"
              />
            </div>
            {corona ? (
              <div>
                <h3>Het coronavirus en onze dienstverlening</h3>
                <p>
                  Het coronavirus beïnvloedde nagenoeg het gehele jaar onze
                  dienstverlening in meer of mindere mate. We formeerden direct
                  een intern corona-crisisteam, dat in de loop van het jaar
                  overging in een corona-adviesteam. Dit team was steeds belast
                  met in kaart brengen van de landelijke maatregelen, nagaan wat
                  de impact ervan was op onze dienstverlening en concreet
                  bepalen hoe wij onze dienstverlening konden aanpassen in lijn
                  met de landelijke richtlijnen en in relatie tot de beschikbare
                  capaciteit van onze professionals.
                </p>
                <h4>
                  Ondanks scherpe keuzes ging belangrijk deel dienstverlening
                  door
                </h4>
                <p>
                  Wij werden gedwongen scherpe keuzes te maken: welk
                  contactmoment en welke zorg moest doorgaan, wat kon worden
                  uitgesteld en wat verviel? We legden de hoogste prioriteit bij
                  onze zorg aan jonge kinderen (de eerste 1000 dagen), zwangeren
                  en kwetsbare kinderen. Ook uitvoering van het
                  Rijksvaccinatieprogramma kreeg prioriteit. Ondanks de scherpe
                  keuzes, waren we in staat een belangrijk deel van onze
                  dienstverlening door te laten gaan. Waar mogelijk deden we dit
                  in aangepaste vorm, bijvoorbeeld telefonisch of via
                  beeldbellen.
                </p>
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>Beeldbellen met het CJG: zo werkt het</h3>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cGQ5SOyoMrA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <p>
                  Een deel van onze dienstverlening kan via beeldbellen,
                  besloten we al snel. Denk aan sommige consulten, cursussen of
                  een themabijeenkomst. We maken een afspraak met een klant,
                  bellen een paar dagen van tevoren, sturen een e-mail met een
                  link en meer informatie, en starten de beeldbelafspraak op het
                  afgesproken tijdstip. Naast een de pagina{" "}
                  <a href="https://www.centrumvoorjeugdengezin.nl/beeldbellen">
                    centrumvoorjeugdengezin.nl/beeldbellen
                  </a>
                  , maakten we een instructievideo. Deze werd overgenomen door
                  meerdere JGZ-organisaties, of en diende als inspiratie.
                </p>
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>
                  Meer thuiswerken, meer koptelefoons <br /> Minder koffie,
                  minder kilometers
                </h3>
                <p>
                  Door het coronavirus werkten onze professionals zoveel
                  mogelijk thuis. Het zorgde ervoor dat we meer beeldbelden en
                  meer koptelefoons nodig hadden, maar ook dat we minder
                  kilometers reisden en op kantoor minder koffie dronken. Zelfs
                  ons percentage ziekteverzuim ging omlaag!
                </p>
                <p>
                  Na de oproep om zoveel mogelijk thuis te werken, tijdens de
                  persconferentie op donderdag 12 maart, ging ook een groot deel
                  van onze professionals naar huis. Waar werken via Microsoft
                  Teams eerst soms nog wat onwennig ging, werd het al snel
                  normaal. Mogelijk hielpen de 500 koptelefoons die we daarvoor
                  bestelden daarbij.
                </p>
                <h4>78.470 minder kopjes koffie</h4>
                <p>
                  De koffieapparaten op onze locaties werden nog nooit zo weinig
                  gebruikt. In 2020 dronken we op onze CJG-locaties 665 kilo
                  minder koffie dan het jaar ervoor! Dat staat gelijk aan
                  ongeveer 78.470 kopjes.
                </p>

                <h4>461.705 minder kilometers</h4>
                <p>
                  Ook gereisd werd er veel minder. Woon-werkverkeer was voor
                  veel van onze professionals verplaatsen van de woon- naar de
                  werkkamer. Of van de bank naar de eettafel. In totaal reisden
                  we in 2020 ongeveer 461.705 minder kilometer dan het jaar
                  ervoor.
                </p>

                <h4>0,8% minder verzuim</h4>
                <p>
                  Het ziekteverzuim daalde van 6,2% eind 2019 naar 5,4% eind
                  2020. Dit komt onder meer doordat in Nederland minder ziektes
                  rondgingen, door betere hygiënemaatregelen zoals handen
                  wassen, niezen in de elleboog en geen handcontact. Ook konden
                  veel professionals thuis toch werken als zij zich niet lekker
                  voelden. Met deze daling sluiten we aan bij de landelijke
                  trend.
                </p>
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>#methartvoorallekinderen</h3>
                <p>
                  Met de hashtag #methartvoorallekinderen, verstuurden ons
                  bestuur en onze directieleden verschillende keren een hart
                  onder de riem. Zo nam onze bestuurder Hans Butselaar eind
                  december deze video op. Hij vraagt erin onder meer: ‘Wat
                  betekent zo’n boodschap, de nieuwe lockdown, voor jou? Als
                  persoon en als collega? Juist in deze tijden is het van belang
                  om contact met elkaar te hebben. Zoek elkaar op, al is het
                  digitaal. En ten slotte: hou vol, blijf gezond!’ De video werd
                  door bijna al onze professionals bekeken.
                </p>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/OKme_RQt7ZU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            ) : null}
            {corona ? (
              <div>
                <h3>57 interne corona-updates intern verstuurd</h3>
                <p>
                  Het corona-crisisteam, dat later overging in het
                  corona-adviesteam, maakte als een van de eerste interne
                  communicatieacties een speciale intranetpagina. Hierop worden
                  continu actuele relevante informatie, documenten, richtlijnen
                  en communicatiemiddelen over het coronavirus gedeeld. Denk aan
                  handelingsperspectieven, veelgestelde vragenlijsten en
                  instructies. Of draaiboeken, registratieformulieren en een
                  routekaart. Daarnaast verscheen wekelijks, soms vaker, een
                  interne corona-update. In 2020 werden in totaal 57 van deze
                  nieuwsbrieven verstuurd!
                </p>
              </div>
            ) : null}
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
