import React from "react";
import AudioPlayer from "react-h5-audio-player";
import audiofile from "./podcast.mp3";
import { ReactComponent as PlayIcon } from "../svg/play.svg";
import { ReactComponent as PauseIcon } from "../svg/pauze.svg";

export default function Player() {
  return (
    <div className="player">
      <AudioPlayer
        // autoPlay
        src={audiofile}
        onPlay={(e) => console.log("onPlay")}
        showJumpControls={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        customIcons={{
          play: <PlayIcon style={{ color: "white", height: "30px" }} />,
          pause: <PauseIcon style={{ color: "white", height: "30px" }} />,
        }}
      />
    </div>
  );
}
