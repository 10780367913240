import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Button from "../../components/button/Button";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import Popup from "../../components/popup/Popup";
import { CoronaContext } from "../../context/coronaContext";
import { settings } from "../popupSettings";
import kinderencorona from "./images/kinderen-corona.svg";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import jouwggd from "./images/jouwggd.jpg";
import prik from "./images/prik.png";

gsap.registerPlugin(ScrollTrigger);

export default function Kinderen() {
  const { corona } = useContext(CoronaContext);
  const [showKinderen, setShowKinderen] = useState(false);

  const numberRef = useRef(null);
  const numberRef2 = useRef(null);
  useEffect(() => {
    gsap.from(numberRef.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      scrollTrigger: numberRef.current,
    });
    gsap.from(numberRef2.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      scrollTrigger: numberRef2.current,
    });
  }, [numberRef, numberRef2]);

  return (
    <div className="section kinderen">
      {corona ? (
        <img
          className="corona-addon"
          src={kinderencorona}
          alt="extra corona illustratie"
        />
      ) : null}
      <div className="section__title">
        <h2>Kinderen</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">3.842</span>chats van jongeren <br />
          op JouwGGD
        </p>
        <p>
          <span className="font--md">87,8%</span> bereikte kinderen
        </p>
        <p>
          <span
            ref={numberRef}
            className="font--xl"
            style={{ display: "inline" }}
          >
            275
          </span>
          <span className="font--xl" style={{ display: "inline" }}>
            .
          </span>
          <span
            ref={numberRef2}
            className="font--xl"
            style={{ display: "inline" }}
          >
            364
          </span>{" "}
          <br />
          kinderen (0-18)
          <br /> in ons werkgebied
        </p>
      </div>

      <Button onClick={() => setShowKinderen(!showKinderen)} />

      {corona ? (
        <CoronaNumber
          number="26.548"
          text="9-, 12-, 14-jarigen kregen vaccinatie op CJG-locatie"
        />
      ) : null}
      {showKinderen ? (
        <Popup toggle={() => setShowKinderen(!showKinderen)}>
          <Slider {...settings}>
            <div>
              <h3>Kansrijke Start: de eerste 1000 dagen zijn cruciaal</h3>
              <p>
                De eerste 1000 dagen van een kind zijn cruciaal om een goede
                start te kunnen maken. In september 2018 lanceerde het
                ministerie van Volksgezondheid Welzijn en Sport daarom het
                landelijke actieprogramma Kansrijke Start. Hoofddoel van dit
                programma: meer kinderen verdienen een kansrijke start, waarbij
                de focus ligt op ondersteuning aan gezinnen in kwetsbare
                situaties.
              </p>
              <p>
                Wij zijn actief in de regionale werkgroep Kansrijke Start
                Rijnmond. Hierin hebben gemeenten in Rijnmond (inclusief
                gemeente Capelle aan den IJssel), ZorgImpuls, CJG Rijnmond, CJG
                Capelle aan den IJssel en Pharos de handen ineen geslagen om te
                komen tot een regionale aanpak Kansrijke Start. In 2020 werden
                twee werkbijeenkomsten georganiseerd over het belang van de
                eerste 1000 dagen. Deze regionale activiteiten worden bovendien
                al gebruikt om lokaal alle spelers in de geboortezorgketen om
                tafel te krijgen.
              </p>
              <p>
                Bekijk ook de{" "}
                <a href="https://www.kansrijkestartnl.nl/">
                  landelijke website van Kansrijke Start
                </a>
                .
              </p>
            </div>
            <div>
              <h3>JouwGGD: ook voor jongeren in onze regio</h3>
              <p>
                Op <a href="https://www.jouwggd.nl/">jouwGGD.nl</a> vinden
                jongeren antwoord op vragen over hun lichaam, gevoel of dingen
                waarover ze thuis liever niet praten. Net als voorgaande jaren
                wisten de jongeren uit onze regio het platform in 2020 te
                vinden. Zo bezochten ze 140.000 keer de website, chatten ze
                3.842 keer en verstuurden ze 354 e-mailberichten. De jongeren
                zochten dit jaar vooral informatie over het coronavirus.
              </p>
              <img src={jouwggd} alt="jouw GGD" />
            </div>
            <div>
              <h3>
                <span style={{ fontStyle: "italic" }}>Kijk mij!</span> Nieuwe
                signalerings- en taxatiemethodiek
              </h3>
              <p>
                Onze nieuwe signalerings- en taxatiemethodiek heet{" "}
                <span style={{ fontStyle: "italic" }}>Kijk mij!</span> De
                methodiek gaat onze professionals helpen om met ouders en
                jeugdigen in gesprek te gaan en een inschatting te maken van hoe
                het met de jeugdige gaat. Deze animatie legt het uit:
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/MFKb9YoLAqs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p>
                Half augustus zagen wij ons genoodzaakt (tijdelijk) te stoppen
                met een deel van onze projecten. Dit gold ook voor de projecten
                die onder CJG Next vallen, waaronder ook{" "}
                <span style={{ fontStyle: "italic" }}>Kijk mij!</span> De
                capaciteit die nodig is voor deze projecten, zowel vanuit de
                uitvoering als de ondersteunende diensten, kon door het
                coronavirus en onze aangepaste dienstverlening niet beschikbaar
                worden gesteld. De prioriteit lag bij ons primaire
                dienstverlening. Het project{" "}
                <span style={{ fontStyle: "italic" }}>Kijk mij!</span> is, net
                als de andere projecten van CJG Next, zo afgerond dat de draad
                zodra dit weer mogelijk is snel opgepakt kan worden.
              </p>
            </div>
            {corona ? (
              <div>
                <h3>
                  Geen vaccinatiedagen, wél bescherming tegen infectieziekten
                </h3>
                <img src={prik} alt="een prik? zo gepiept!" />
                <p>
                  In Nederland zijn wij een van de organisaties die het
                  Rijksvaccinatieprogramma uitvoeren. De vaccinaties beschermen
                  kinderen tegen{" "}
                  <a href="https://www.eenprikzogepiept.nl">
                    12 ernstige infectieziekten
                  </a>{" "}
                  die vaak zeer besmettelijk zijn en vooral bij jonge kinderen
                  ernstige gevolgen kunnen hebben. In onze gemeenten vaccineren
                  wij alle kinderen tot 4 jaar op het consultatiebureau. Voor
                  kinderen van 9 jaar en ouder organiseren wij 2 keer per jaar,
                  in het voor- en najaar, vaccinatiedagen.
                </p>
                <p>
                  Door het coronavirus werden deze vaccinatiedagen half maart
                  geannuleerd. Als alternatief daarvoor nodigden wij ook deze
                  groep uit op onze CJG-locaties. Zo ontvingen jongeren die zijn
                  geboren in 2006 een persoonlijke uitnodiging voor de
                  vaccinaties tegen de meningokokkenziekte typen A, C, W en Y
                  (MenACWY), zijn 12/13-jarige meisjes uitgenodigd voor 2
                  HPV-prikken (tegen het virus dat onder meer
                  baarmoederhalskanker kan veroorzaken) en nodigden wij 9-jarige
                  kinderen uit voor 2 prikken tegen difterie, tetanus, polio
                  (DTP) en bof, mazelen en rodehond (BMR).
                </p>
              </div>
            ) : null}
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
