import React, { useEffect, useRef, useState } from "react";
import Ouders from "./sections/ouders/Ouders";
import { CoronaContext } from "./context/coronaContext";
import Socials from "./sections/socials/Socials";
import CjgRijnmond from "./sections/CJG/CjgRijnmond";
import Scholen from "./sections/scholen/Scholen";
import Kinderen from "./sections/kinderen/Kinderen";
import Zorg from "./sections/zorg/Zorg";
import Gemeenten from "./sections/gemeenten/Gemeenten";
import logo from "./images/SVG/top/logo.svg";
import Hero from "./sections/hero/Hero";
import Jeugd from "./sections/Jeugdgezondsheidszorg/Jeugd";
import jaaronder from "./images/SVG/footer.svg";
import achtergrondfooter from "./images/achtergrondfooter.svg";
import CoronaPopup from "./sections/coronapopup/CoronaPopup";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function App() {
  const [corona, setCorona] = useState(false);

  const footer = useRef(null);
  useEffect(() => {
    gsap.from(footer.current, {
      opacity: 0.5,
      duration: 1,
      ease: Power3.easeInOut,

      scrollTrigger: footer.current,
    });
  }, [footer]);

  return (
    <CoronaContext.Provider value={{ corona, setCorona }}>
      <div className="app">
        <div className="app__topbar">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="app__hero">
          <Hero />
          <CoronaPopup />
        </div>
        <div className="app__content">
          <Socials />
          <Ouders />
          <CjgRijnmond />
          <Scholen />
          <Kinderen />
          <Zorg />
          <Gemeenten />
          <Jeugd />
        </div>
        <div className="app__footer">
          <img src={jaaronder} alt="2020" className="jaaronder" ref={footer} />
          <img
            src={achtergrondfooter}
            alt="achtergrond footer"
            className="footerachtergrond"
          />
        </div>
      </div>
    </CoronaContext.Provider>
  );
}

export default App;
