import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Button from "../../components/button/Button";
import CoronaNumber from "../../components/coronaNumber/CoronaNumber";
import Popup from "../../components/popup/Popup";
import { CoronaContext } from "../../context/coronaContext";
import { settings } from "../popupSettings";
import zorgcorona from "./images/zorg-corona.svg";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Zorg() {
  const { corona } = useContext(CoronaContext);
  const [showZorg, setShowZorg] = useState(false);

  const numberRef = useRef(null);
  useEffect(() => {
    gsap.from(numberRef.current, {
      textContent: 0,
      duration: 2,
      ease: Power3.easeIn,
      snap: { textContent: 1 },
      scrollTrigger: numberRef.current,
    });
  }, [numberRef]);

  return (
    <div className="section zorg">
      {corona ? (
        <img
          className="corona-addon"
          src={zorgcorona}
          alt="extra corona illustratie"
        />
      ) : null}
      <div className="section__title">
        <h2>Zorg</h2>
      </div>
      <div className="section__numbers">
        <p>
          <span className="font--md">200ste</span>
          geboortedag Florence Nightingale
        </p>
        <p>
          <span ref={numberRef} className="font--xl">
            2,5
          </span>
          miljoen extra Kansrijke Start
        </p>
      </div>

      <Button onClick={() => setShowZorg(!showZorg)} />

      {corona ? (
        <CoronaNumber number="11" text="fte voor samenwerking GGD" />
      ) : null}
      {showZorg ? (
        <Popup toggle={() => setShowZorg(!showZorg)}>
          <Slider {...settings}>
            <div>
              <h3>
                Lof der Verpleegkunst viert 200ste geboortedag Florence
                Nightingale
              </h3>
              <p>
                Om te laten zien hoe mooi het verpleegkundig vak is, het beroep
                zichtbaarder te maken en bij te dragen aan het verminderen van
                de dreigende schaarste, werd in 2017 de stichting Lof der
                Verpleegkunst opgericht. Jaarlijks organiseert de stichting het
                symposium{" "}
                <span style={{ fontStyle: "italic" }}>
                  Lof der Verpleegkunst
                </span>
                . In 2020, het internationale jaar van de verpleging, gebeurde
                dit via een livestream. De deelnemers vierden de 200ste
                geboortedag van Florence Nightingale en woonden een programma
                bij met lezingen over onder meer zorgtechnologie in de
                verpleegkunde. Ook werd de Lof der Verpleegkunst prijs 2020
                uitgereikt.
              </p>
              <p>
                Lof der Verpleegkunst 2020 terugkijken?{" "}
                <a href="https://www.lofderverpleegkunst.nl/terugkijken/">
                  Bekijk dan de video
                </a>
                .
              </p>
            </div>
            <div>
              <h3>
                Sleutelpersonen in gesprek over vrouwelijke genitale verminking
              </h3>
              <p>
                In Nederland wonen naar schatting 41.000 meisjes en vrouwen die
                besneden zijn. Van hen hebben 15.000 waarschijnlijk de meest
                ingrijpende vorm van Vrouwelijke Genitale Verminking (VGV), ook
                wel meisjesbesnijdenis genoemd, ondergaan. De meeste vrouwen
                zijn afkomstig uit Somalië, Egypte, Ethiopië, Eritrea, Soedan en
                Irak.
              </p>
              <p>
                In Nederland is er een goede ketenaanpak VGV, dit betekent dat
                preventie, zorg, wetshandhaving en voorlichting – vooral door de
                inzet van onmisbare sleutelpersonen – goed is neergezet. Het is
                juist de combinatie van preventie, wetshandhaving en aandacht
                voor goede zorg (voor vrouwen die al besneden zijn) die werkt.
              </p>
              <p>
                In Rotterdam werken wij binnen deze aanpak intensief samen met
                sleutelpersonen afkomstig uit de risicogebieden. Zij kennen de
                gemeenschap en kunnen in gesprek gaan met de meisjes, vrouwen en
                mannen. De sleutelpersonen organiseren huiskamerbijeenkomsten en
                huisbezoeken. Door het coronavirus konden deze bijeenkomsten in
                2020 minder vaak doorgaan. Toch hadden de sleutelpersonen ook
                tijdens de lockdowns goed contact met hun netwerk; telefonisch,
                via WhatsApp en via digitale huiskamerbijeenkomsten.
              </p>
            </div>
            <div>
              <h3>
                Laagdrempelige hulp voor jongeren in het centrum van Rotterdam
              </h3>
              <p>
                Op onze locatie in de Centrale Bibliotheek in Rotterdam ging op
                14 oktober @ease van start. @ease is voor jongeren van 12 tot 25
                jaar met (beginnende) psychische en sociale problemen. Om de
                drempel om hulp te zoeken te verlagen, kunnen jongeren hier
                gratis en anoniem binnenlopen voor een gesprek.
              </p>
              <p>
                Om de drempel om hulp te zoeken te verlagen, kunnen jongeren
                hier gratis en anoniem binnenlopen voor een gesprek. Er is
                altijd een zorgprofessional aanwezig die vragen kan
                beantwoorden. Als het nodig is, kan deze ook helpen de juiste
                zorg in de reguliere (geestelijke) gezondheidszorg te vinden.
                Daarnaast is er een psychiater bereikbaar als achterwacht in het
                geval van crisis.{" "}
                <a href="https://cjgrijnmond.nl/laagdrempelige-hulp-voor-jongeren-in-het-centrum-van-rotterdam/">
                  Lees meer over @ease op onze website
                </a>
                . Of bekijk deze video:
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/46CiwAWb1bc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
