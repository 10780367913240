import React, { useState } from "react";
import Slider from "react-slick";
import Popup from "../../components/popup/Popup";
import { settings } from "../popupSettings";
import coronaOranje from "./images/corona-oranje.svg";
// import dagplanner from "./images/Dagplanner_CJG.jpg";
import weekplanner from "./images/Weekplanner_CJG.jpg";
import nieuwsbrief from "./images/nieuwsbrief.png";
import oudersnieuwsbrief from "./images/oudersnieuwsbrief.png";
import richtlijnen from "./images/richtlijnen.png";
import prik from "./images/prik.png";
import Nji from "./images/Nji.png";
import posterhandenwassen from "./images/poster-handen-wassen.jpg";
import valerie from "./images/valerie.png";
import Button from "../../components/button/Button";

export default function CoronaPopup() {
  const [showCoronaPopup, setShowCoronaPopup] = useState(false);

  console.log("corona popup", showCoronaPopup);

  return (
    <div className="coronaPopup">
      <img
        className="coronaButton"
        src={coronaOranje}
        alt=""
        onClick={() => setShowCoronaPopup(!showCoronaPopup)}
      />
      <Button onClick={() => setShowCoronaPopup(!showCoronaPopup)} />

      {showCoronaPopup ? (
        <Popup toggle={() => setShowCoronaPopup(!showCoronaPopup)}>
          <Slider {...settings}>
            <div>
              <h3>Het coronavirus en onze dienstverlening</h3>
              <p>
                Het coronavirus beïnvloedde nagenoeg het gehele jaar onze
                dienstverlening in meer of mindere mate. We formeerden direct
                een intern corona-crisisteam, dat in de loop van het jaar
                overging in een corona-adviesteam. Dit team was steeds belast
                met in kaart brengen van de landelijke maatregelen, nagaan wat
                de impact ervan was op onze dienstverlening en concreet bepalen
                hoe wij onze dienstverlening konden aanpassen in lijn met de
                landelijke richtlijnen en in relatie tot de beschikbare
                capaciteit van onze professionals.
              </p>
              <h4>
                Ondanks scherpe keuzes ging belangrijk deel dienstverlening door
              </h4>
              <p>
                Wij werden gedwongen scherpe keuzes te maken: welk contactmoment
                en welke zorg moest doorgaan, wat kon worden uitgesteld en wat
                verviel? We legden de hoogste prioriteit bij onze zorg aan jonge
                kinderen (de eerste 1000 dagen), zwangeren en kwetsbare
                kinderen. Ook uitvoering van het Rijksvaccinatieprogramma kreeg
                prioriteit. Ondanks de scherpe keuzes, waren we in staat een
                belangrijk deel van onze dienstverlening door te laten gaan.
                Waar mogelijk deden we dit in aangepaste vorm, bijvoorbeeld
                telefonisch of via beeldbellen.
              </p>
            </div>
            <div>
              <h3>Online coronavraagbaak voor ouders</h3>
              <p>
                Is mijn contactmoment digitaal of fysiek, met welke maatregelen
                moet ik rekening houden als ik naar een CJG-locatie kom en kan
                de afspraak doorgaan als mijn kind verkouden is? Voor antwoord
                op deze en andere coronavragen maakten we gelijk na de uitbraak
                van het coronavirus een speciale coronapagina{" "}
                <a href="https://centrumvoorjeugdengezin.nl/themas/corona">
                  CJG en het coronavirus
                </a>
                . Naast actuele informatie, staan hier handige apps en websites,
                blogs van professionals en bijvoorbeeld een week- en dagplanning
                om ouders te ondersteunen bij de combinatie thuiswerken,
                kinderen opvangen en lesgeven.
              </p>
              <div>
                <img src={weekplanner} alt="weekplanner" />
                {/* <img src={dagplanner} alt="dagplanner" /> */}
              </div>
            </div>
            <div>
              <h3>
                Extra aandacht voor kwetsbare kinderen,{" "}
                <span style={{ display: "inline-block" }}>juist nu</span>
              </h3>
              <p>
                Bij de keuzes die wij moesten maken voor onze dienstverlening,
                legden wij de hoogste prioriteit bij onze zorg aan jonge
                kinderen, zwangeren en kwetsbare kinderen. Onze professionals
                die verbonden zijn aan de scholen, hielden in samenwerking met
                de scholen kwetsbare kinderen in beeld. In specifieke gevallen
                nodigden we de kinderen uit voor een (digitaal) consult en
                indien nodig verwezen we door naar passende hulp. Daarnaast
                haalden onze professionals signalen op uit het netwerk en namen
                zij proactief contact op met de gezinnen die (mogelijk)
                kwetsbaar zijn.
              </p>
              <p>
                Jeugdverpleegkundige Minke Vellinga is een van onze
                professionals die zich onvermoeibaar blijft inzetten voor
                kwetsbare kinderen in de schoolleeftijd en daar ook in de media
                aandacht voor vraagt. Zo werkte ze mee aan een{" "}
                <a href="https://www.nporadio1.nl/binnenland/22953-5200-leerlingen-buiten-beeld-geen-onderwijskwestie-maar-een-veiligheidskwestie">
                  radio-interview bij Spraakmakers
                </a>
                . Minke is aan het woord vanaf minuut 5.31.
              </p>
            </div>
            <div>
              <h3>Beeldbellen met het CJG: zo werkt het</h3>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/cGQ5SOyoMrA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p>
                Een deel van onze dienstverlening kan via beeldbellen, besloten
                we al snel. Denk aan sommige consulten, cursussen of een
                themabijeenkomst. We maken een afspraak met een klant, bellen
                een paar dagen van tevoren, sturen een e-mail met een link en
                meer informatie, en starten de beeldbelafspraak op het
                afgesproken tijdstip. Naast een de pagina{" "}
                <a href="https://www.centrumvoorjeugdengezin.nl/beeldbellen">
                  centrumvoorjeugdengezin.nl/beeldbellen
                </a>
                , maakten we een instructievideo. Deze werd overgenomen door
                meerdere JGZ-organisaties, of en diende als inspiratie.
              </p>
            </div>
            <div>
              <h3>
                Extra nieuwsbrief voor stakeholders en samenwerkingspartners
              </h3>
              <p style={{ fontStyle: "italic" }}>
                De verspreiding van het coronavirus (COVID-19) en de
                onvoorspelbaarheid daarvan, beheersen op dit moment onze
                samenleving. De verscherpte landelijke maatregelen, zoals
                afgelopen zondag zijn ingesteld door de regering, hebben
                vanzelfsprekend ook invloed op de dienstverlening van CJG
                Rijnmond. Zo verminderen wij het aantal contactmomenten, doen
                wij consulten waar mogelijk telefonisch en gaan de
                vaccinatiedagen niet door. Wij hebben daarvoor nauw contact met
                het RIVM, GGD Rotterdam-Rijnmond en volgen de landelijke
                richtlijnen van de JGZ.
              </p>
              <p>
                Met deze woorden begon de extra nieuwsbrief die wij op woensdag
                18 maart verstuurden. De{" "}
                <a href="https://cjgrijnmond.nl/nieuwsbrief-23/">
                  speciale uitgave
                </a>{" "}
                van onze{" "}
                <a href="https://cjgrijnmond.nl/nieuwsbrief/">
                  maandelijkse digitale nieuwsbrief
                </a>{" "}
                voor samenwerkingspartners, stakeholders en professionals stond
                volledig in het teken van het coronavirus en onze aangepaste
                dienstverlening.
              </p>
              <img src={nieuwsbrief} alt="nieuwsbrief" />
            </div>
            <div>
              <h3>85.000 ouders krijgen coronanieuwsbrief</h3>
              <p>
                Om de ouders en verzorgers in ons werkgebied zo goed mogelijk te
                informeren over de gevolgen van het coronavirus voor onze
                dienstverlening, verstuurden we eind maart 2020 een{" "}
                <a href="https://mailchi.mp/246c692f871f/nieuwsbrief-coronavirus-en-het-centrum-voor-jeugd-en-gezin">
                  digitale nieuwsbrief
                </a>
                . We vertelden daarin onder meer over onze maatregelen en de
                vaccinatiedagen die niet doorgingen. Ook gaven we antwoord op de
                meestgestelde vragen van ouders en verwezen we naar de website{" "}
                <a href="https://www.centrumvoorjeugdengezin.nl/corona">
                  centrumvoorjeugdengezin.nl/corona
                </a>
                . In april en mei verstuurden we de 85.000 ontvangers opnieuw
                per e-mail actuele informatie.
              </p>
              <img src={oudersnieuwsbrief} alt="ouders nieuwsbrief" />
            </div>
            <div>
              <h3>
                Richtlijnen CJG-locaties: 1,5 meter afstand, 1 begeleider en
                handen wassen
              </h3>
              <p>
                Op onze CJG-locaties waren de veiligheidsmaatregelen en
                RIVM-richtlijnen al snel zichtbaar en voelbaar. Het vroeg steeds
                aanpassingsvermogen van zowel onze professionals als onze
                klanten. We zetten in op zo min mogelijk verkeer van personen.
                Dit om besmettingsrisico te minimaliseren, voldoende ruimte te
                hebben om de 1,5 meter afstandsregel te kunnen hanteren en
                contactmomenten met lichamelijk contact veilig door te kunnen
                laten gaan. We planden onze spreekuren minder vol en minder
                tegelijkertijd, zodat zo min mogelijk ouders en kinderen op
                hetzelfde moment bij ons binnen waren.{" "}
              </p>
              <p>
                Aan onze klanten vroegen we onder meer om met slechts één
                begeleider per kind te komen, bij binnenkomst de handen te
                wassen en een registratieformulier in te vullen. Dit vroeg nog
                wel eens om uitleg en geduld van onze assistenten en
                baliemedewerkers. Om hen bij deze soms moeilijke gesprekken te
                ondersteunen, organiseerden we in het najaar bijeenkomsten via
                Microsoft Teams om ervaringen uit te wisselen en tips te delen.
              </p>
              <img src={richtlijnen} alt="coronavirus richtlijnen" />
              <img src={posterhandenwassen} alt="poster handen wassen" />
            </div>
            <div>
              <h3>
                Meer thuiswerken, meer koptelefoons <br /> Minder koffie, minder
                kilometers
              </h3>
              <p>
                Door het coronavirus werkten onze professionals zoveel mogelijk
                thuis. Het zorgde ervoor dat we meer beeldbelden en meer
                koptelefoons nodig hadden, maar ook dat we minder kilometers
                reisden en op kantoor minder koffie dronken. Zelfs ons
                percentage ziekteverzuim ging omlaag!
              </p>
              <p>
                Na de oproep om zoveel mogelijk thuis te werken, tijdens de
                persconferentie op donderdag 12 maart, ging ook een groot deel
                van onze professionals naar huis. Waar werken via Microsoft
                Teams eerst soms nog wat onwennig ging, werd het al snel
                normaal. Mogelijk hielpen de 500 koptelefoons die we daarvoor
                bestelden daarbij.
              </p>
              <h4>78.470 minder kopjes koffie</h4>
              <p>
                De koffieapparaten op onze locaties werden nog nooit zo weinig
                gebruikt. In 2020 dronken we op onze CJG-locaties 665 kilo
                minder koffie dan het jaar ervoor! Dat staat gelijk aan ongeveer
                78.470 kopjes.
              </p>

              <h4>461.705 minder kilometers</h4>
              <p>
                Ook gereisd werd er veel minder. Woon-werkverkeer was voor veel
                van onze professionals verplaatsen van de woon- naar de
                werkkamer. Of van de bank naar de eettafel. In totaal reisden we
                in 2020 ongeveer 461.705 minder kilometer dan het jaar ervoor.
              </p>

              <h4>0,8% minder verzuim</h4>
              <p>
                Het ziekteverzuim daalde van 6,2% eind 2019 naar 5,4% eind 2020.
                Dit komt onder meer doordat in Nederland minder ziektes
                rondgingen, door betere hygiënemaatregelen zoals handen wassen,
                niezen in de elleboog en geen handcontact. Ook konden veel
                professionals thuis toch werken als zij zich niet lekker
                voelden. Met deze daling sluiten we aan bij de landelijke trend.
              </p>
            </div>
            <div>
              <h3>
                Geen vaccinatiedagen, wél bescherming tegen infectieziekten
              </h3>
              <img src={prik} alt="een prik? zo gepiept!" />
              <p>
                In Nederland zijn wij een van de organisaties die het
                Rijksvaccinatieprogramma uitvoeren. De vaccinaties beschermen
                kinderen tegen{" "}
                <a href="https://www.eenprikzogepiept.nl">
                  12 ernstige infectieziekten
                </a>{" "}
                die vaak zeer besmettelijk zijn en vooral bij jonge kinderen
                ernstige gevolgen kunnen hebben. In onze gemeenten vaccineren
                wij alle kinderen tot 4 jaar op het consultatiebureau. Voor
                kinderen van 9 jaar en ouder organiseren wij 2 keer per jaar, in
                het voor- en najaar, vaccinatiedagen.
              </p>
              <p>
                Door het coronavirus werden deze vaccinatiedagen half maart
                geannuleerd. Als alternatief daarvoor nodigden wij ook deze
                groep uit op onze CJG-locaties. Zo ontvingen jongeren die zijn
                geboren in 2006 een persoonlijke uitnodiging voor de vaccinaties
                tegen de meningokokkenziekte typen A, C, W en Y (MenACWY), zijn
                12/13-jarige meisjes uitgenodigd voor 2 HPV-prikken (tegen het
                virus dat onder meer baarmoederhalskanker kan veroorzaken) en
                nodigden wij 9-jarige kinderen uit voor 2 prikken tegen
                difterie, tetanus, polio (DTP) en bof, mazelen en rodehond
                (BMR).
              </p>
            </div>
            <div>
              <h3>Actuele corona-informatie op themapagina NJi.nl</h3>
              <p>
                Hoe gaan we als samenleving de komende tijd om met het
                coronavirus? Het Nederlands Jeugdinstituut (NJi) maakte{" "}
                <a href="https://www.nji.nl/coronavirus">een themapagina</a> met
                een verzameling van alle actuele informatie hierover. Kinderen,
                jongeren, ouders, professionals en beleidsmakers vinden hier
                betrouwbare informatie en antwoord op actuele vragen over het
                coronavirus en de invloed hiervan op opgroeien en opvoeden.
              </p>
              <img src={Nji} alt="nji screendump" />
            </div>
            <div>
              <h3>CJG-professionals ondersteunen GGD Rotterdam-Rijnmond</h3>
              <img src={valerie} alt="quote van Valerie" />
              <p>
                Vanuit een bestaand convenant en het crisisplan Infectiezieken
                ondersteunden een aantal van onze professionals (11 fte) van
                maart tot september GGD Rotterdam-Rijnmond. Jeugdarts Valerie
                Schokkenbroek was een van hen. Ze deelde geregeld haar
                ervaringen: 'We nemen monsters af bij patiënten thuis en
                telefonisch beantwoorden wij vragen van huisartsen en mensen uit
                de regio. Ik vind het mooi om te zien hoe de samenwerking
                verloopt: naast het CJG helpen ook andere organisaties de GGD,
                en CJG-collega’s nemen mijn gewone taken over in de weken dat ik
                bij de GGD werk! Wat mij erg opvalt is dat er veel emotionele en
                sociale steun is onderling. Laten we dat vooral vasthouden als
                alles weer normaler wordt!’
              </p>
            </div>
            <div>
              <h3>#methartvoorallekinderen</h3>
              <p>
                Met de hashtag #methartvoorallekinderen, verstuurden ons bestuur
                en onze directieleden verschillende keren een hart onder de
                riem. Zo nam onze bestuurder Hans Butselaar eind december deze
                video op. Hij vraagt erin onder meer: ‘Wat betekent zo’n
                boodschap, de nieuwe lockdown, voor jou? Als persoon en als
                collega? Juist in deze tijden is het van belang om contact met
                elkaar te hebben. Zoek elkaar op, al is het digitaal. En ten
                slotte: hou vol, blijf gezond!’ De video werd door bijna al onze
                professionals bekeken.
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/OKme_RQt7ZU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <h3>57 interne corona-updates verstuurd</h3>
              <p>
                Het corona-crisisteam, dat later overging in het
                corona-adviesteam, maakte als een van de eerste interne
                communicatieacties een speciale intranetpagina. Hierop worden
                continu actuele relevante informatie, documenten, richtlijnen en
                communicatiemiddelen over het coronavirus gedeeld. Denk aan
                handelingsperspectieven, veelgestelde vragenlijsten en
                instructies. Of draaiboeken, registratieformulieren en een
                routekaart. Daarnaast verscheen wekelijks, soms vaker, een
                interne corona-update. In 2020 werden in totaal 57 van deze
                nieuwsbrieven verstuurd!
              </p>
            </div>
          </Slider>
        </Popup>
      ) : null}
    </div>
  );
}
