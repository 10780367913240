import React from "react";
import button from "../svg/button.svg";

export default function Popup({ toggle, children }) {
  return (
    <div className="popup">
      <div className="overlay"></div>
      <div className="popup__content">
        <img
          className="btn btn--close"
          src={button}
          alt="button"
          onClick={toggle}
        />

        {children}
      </div>
    </div>
  );
}
